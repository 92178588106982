/* :root {
 --primary-background-color: #ffffff;
  --primary-text-color: #0e0e0e;
  --secondary-background-color: #eeeeee;
  --secondary-text-color: rgba(103,88,3,1);
  --tertiary-background-color: #ff8034;
  --tertiary-text-color: rgba(103,88,3,1);
  --cc: #66afe9; 
}*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
