/* #nav-toggle-state {
  display: none;
}

#nav-toggle-state:checked ~ .nav-menu {
  display: block;
} */

/* #nav-toggle-state {
  display: none;
}

#nav-toggle-state:checked ~ .navbar-menu {
  display: block;
} */

.button.cta {
  background-color: rgba(103, 88, 3, 1);
}
.button.cta:hover {
  background-color: hsl(59, 76%, 45%);
  border-color: transparent;
  color: #fff;
}

.button.cta-cancel {
  background-color: #fafafa;
  color: rgba(103, 88, 3, 1);
}
.button.cta-cancel:hover {
  background-color: hsl(0, 54%, 70%);
  border-color: transparent;
  color: #fff;
}

/* .button.is-link.is-hovered, .button.is-link:hover {
    background-color: hsl(348, 86%, 61%);
    border-color: transparent;
    color: #fff;
} */

.button.login,
.button.loggout {
  color: #fff;
}

.button.loggout.is-hovered,
.button.loggout:hover {
  background-color: hsl(348, 86%, 61%);
  border-color: transparent;
  color: #fff;
}

.button.login.is-hovered,
.button.login:hover {
  background-color: #fff;
  border-color: transparent;
  color: rgba(103, 88, 3, 1);
  border: 1px solid;
}

.greeting {
  display: none;
  padding: 0.25rem 0.75rem;
}

.navbar-burger:hover {
  background-color: #fff;
}

@media (min-width: 640px) {
  .greeting {
    display: inline;
  }
}
