.fancy-effect {
  transition: all 0.5s ease; /* back to normal */
  padding-top: 2rem;
}

.fancy-effect:hover {
  box-shadow: 0 0.5em 1em -0.125em rgba(59, 85, 120, 1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
  transform: translate3D(0, -1px, 0) scale(1.03); /* move up slightly and zoom in */
  transition: all 0.4s ease; /* zoom in */
  cursor: pointer;
}

.background-pattern {
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%238b7e85' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.bgc-hero-title {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.hero {
  padding: 2rem 0 10rem 0;
}

.bgc-hero-title h1 {
  font-size: 2.5rem;
  color: rgb(139, 126, 133);
}

.bgc-hero-title h2 {
  font-size: 1.5rem;
  color: rgb(139, 126, 133);
}

.hero-body {
  height: 70vh;
  background-color: rgba(133, 129, 126, 0.1);
}

.move-up {
  margin-top: -21rem;
}

.btn-start-now {
  font-size: 1rem;
  margin: 0 auto;
  padding: 0.5rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  margin-bottom: 2rem;
  background-color: rgba(103, 88, 3, 1);
  border: 1px solid rgba(103, 88, 3.1);
  border-radius: 5px;
  color: #fff;
}

.btn-start-now.cta {
  background-color: rgba(103, 88, 3.1);
}
.btn-start-now.cta:hover {
  background-color: hsl(59, 76%, 45%);
  border-color: transparent;
  color: #fff;
}

.btn-start-now.cta-cancel {
  background-color: #fafafa;
  color: rgba(103, 88, 3.1);
}
.btn-start-now.cta-cancel:hover {
  background-color: hsl(0, 54%, 70%);
  border-color: transparent;
  color: #fff;
}

.btn-start-now.login {
  color: #fff;
}

.btn-start-now.login.is-hovered,
.btn-start-now.login:hover {
  background-color: #fff;
  border-color: transparent;
  color: rgba(103, 88, 3, 1);
  border: 1px solid;
}

.navbar-item {
  z-index: 10;
}

.stats {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.expense-item {
  background-color: rgba(103, 88, 3, 1);
  color: rgba(255, 255, 255, 1);
}

@media (min-width: 640px) {
  .move-up {
    margin-top: -18rem;
  }

  .hero {
    height: 80vh;
  }

  .bgc-hero-title {
    padding: 4rem 0;
  }

  .start-now {
    font-size: 1.2rem;
    padding: 0.8rem;
    font-weight: 600;
  }
}

@media (min-width: 1024px) {
  .hero {
    height: 90vh;
  }
  .move-up {
    margin-top: -22rem;
  }

  .bgc-hero-title h1 {
    font-size: 4.5rem;
  }

  .bgc-hero-title h2 {
    font-size: 2.5rem;
  }
}

@media (min-width: 1280px) {
  .move-up {
    margin-top: -29rem;
  }
}
